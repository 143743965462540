import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import Banner from "./banner.jsx";
import Container from "../container/container.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "banner"
    }}>{`Banner`}</h1>
    <p>{`A component that will stretch to use the full viewport width`}</p>

    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Banner} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Banner className=\"campaign-banner\">\n  <Container>\n    <p className=\"campaign-banner__text\">This is a banner</p>\n  </Container>\n</Banner>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Banner,
      Container,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Banner className="campaign-banner" mdxType="Banner">
    <Container mdxType="Container">
      <p className="campaign-banner__text">This is a banner</p>
    </Container>
  </Banner>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      